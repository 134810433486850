import CoverSlideshow from "@/components/CoverSlideshow";
import { Footer } from "@/components/Footer";
import { PageInfo } from "@/components/PageInfo";
import { Deployment, deploymentFromContext } from "@/domain/deployment";
import { DEFAULT_LANGUAGE, useI18n } from "@/domain/i18n";
import { MenuItem } from "@/domain/menu";
import { fetchMenuItems } from "@/domain/menu.ssr";
import { Page } from "@/domain/page";
import { fetchPage } from "@/domain/page.ssr";
import { SlideshowItem } from "@/domain/slideshow";
import { fetchSlideshowItems } from "@/domain/slideshow.ssr";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import React from "react";

const IntroVis = dynamic(() => import("@/components/IntroVisWrapper"), { ssr: false });
const AutoToggleHeader = dynamic(() => import("@/components/Header"), { ssr: false });

interface Props {
  page: Page;
  menuItems: Array<MenuItem>;
  slideshowItems: Array<SlideshowItem>;
  deployment: Deployment;
}

export const getServerSideProps: GetServerSideProps<Props> = async (ctx) => {
  const deployment = deploymentFromContext(ctx);
  const locale = ctx.locale ?? DEFAULT_LANGUAGE;

  const page_id = ""; // This is the index page
  const page = await fetchPage(deployment, locale, page_id);
  if (!page) {
    return {
      notFound: true,
    };
  }

  const menuItems = fetchMenuItems(deployment, locale);
  const slideshowItems = fetchSlideshowItems(deployment, locale);

  return {
    props: {
      page,
      menuItems: await menuItems,
      slideshowItems: await slideshowItems,
      deployment,
    },
  };
};

export default function Home(props: Props) {
  const I18n = useI18n();

  const { page, menuItems, slideshowItems, deployment } = props;

  return (
    <>
      <PageInfo {...page.meta} deployment={deployment} />

      <div className="landing-page">
        <AutoToggleHeader menuItems={menuItems} deployment={deployment} />

        <CoverSlideshow items={slideshowItems} />

        <div className="phxxl" style={{ maxWidth: "1440px", margin: "0 auto" }}>
          <Section title={I18n.t("home.section1.title")} body={<p>{I18n.t("home.section1.text")}</p>}>
            <IntroVis deployment={deployment} targetStep={0} />
          </Section>

          <Section title={I18n.t("home.section2.title")} body={<p>{I18n.t("home.section2.text")}</p>}>
            <IntroVis deployment={deployment} targetStep={1} />
          </Section>

          <Section title={I18n.t("home.section3.title")} body={<p>{I18n.t("home.section3.text")}</p>}>
            <IntroVis deployment={deployment} targetStep={3} />
          </Section>

          <Section
            title={I18n.t("home.section4.title")}
            body={
              <>
                <p>{I18n.t("home.section4.text")}</p>
                <p className="mtm">
                  <a className="link link--arrow" href={`/${I18n.locale}/atlas`}>
                    {I18n.t("home.section4.action")}
                  </a>
                </p>
              </>
            }
          >
            <IntroVis deployment={deployment} targetStep={4} />
          </Section>
        </div>
      </div>

      <Footer />
    </>
  );
}

// -----------------------------------------------------------------------------

function Section({ title, body, children }: React.PropsWithChildren<{ title: string; body: React.ReactNode }>) {
  return (
    <section
      className="landing-page__sections__section"
      style={{
        position: "relative",
        height: "60vh",
        minHeight: "480px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "200px",
      }}
    >
      <div className="mrxxl" style={{ width: "40%" }}>
        <h1>{title}</h1>
        {body}
      </div>
      <div style={{ position: "relative", width: "100%", height: "60vw", maxHeight: "800px" }}>{children}</div>
    </section>
  );
}
